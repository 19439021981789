import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import profilePic from '../assets/profile_pic.jpg';

const AboutMe = () => {
  return (
    <Grid>
      <Grid.Column width={8}>
        <Image 
          fluid
          src={profilePic}
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <h1>About Me</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus nec sagittis ligula. Cras placerat, sapien a faucibus sagittis, velit enim mollis tellus, sit amet blandit turpis massa ut nunc. Nulla nisi tellus, rutrum vitae leo et, cursus semper est. Curabitur tristique massa at fringilla tincidunt. Mauris lectus leo, laoreet vitae viverra vel, laoreet sed nisl. Curabitur in sapien non est vulputate tempor eget et enim. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent dictum mauris
        </p>
        <p>
          Nunc porta convallis laoreet. Nulla fermentum feugiat nunc non posuere. Quisque vel semper ipsum. Aenean non lacinia est, ut commodo dui. Duis non consectetur nisi. Aenean tincidunt mi est. Morbi et risus vestibulum, pretium eros ut, tincidunt est. Fusce vitae risus nec massa porttitor fringilla eu id magna. Nam sit amet leo turpis. Phasellus tincidunt ipsum dolor. Etiam velit orci, porttitor vitae finibus a, euismod quis nunc.
        </p>
        <h4>Contact Info</h4>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam hendrerit, nisl eget maximus cursus, est tortor rutrum urna, eu sodales turpis tellus sed nunc. Aenean fringilla nisi tempus bibendum rutrum.
        </p>
      </Grid.Column>
    </Grid>
  );
}

export default AboutMe;