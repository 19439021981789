import React from 'react';
import { Grid, Image, Divider } from 'semantic-ui-react';
import logo from '../assets/logov01.svg';
import project1Thumbnail from '../assets/project1_main.jpg';
import project2Thumbnail from '../assets/project2_main.jpg';
import project3Thumbnail from '../assets/project3_main.png';

const Projects = () => {
  return (
    <Grid>
      <Grid.Row className="main-banner">
        <Grid.Column 
            className="heading-container"
            width={10}
        >
          <h1>Prerana Jaikumar</h1>
          <h1>Product Management</h1>
          <h3>Improving healthcare through smarter data management</h3>
        </Grid.Column>
        
        <Grid.Column width={4} >
          <Image 
            src={logo} 
            fluid 
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width={16}>
        <Grid>
          <Grid.Row className="project-snippet">
            <Grid.Column width={7}>
              <Image 
                src={project1Thumbnail} 
                fluid 
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <div className="vertical-divider pastel-red"></div>
            </Grid.Column>
            <Grid.Column width={7}>
              <h4>Project 1</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer at sem risus. Aenean blandit leo in porttitor fermentum. Praesent at justo nisi. Curabitur facilisis facilisis leo in imperdiet. In ullamcorper consectetur lectus at porttitor. Nam at blandit felis, nec tempus odio. </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="project-snippet">
            <Grid.Column width={7}>
              <h4>Project 2</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer at sem risus. Aenean blandit leo in porttitor fermentum. Praesent at justo nisi. Curabitur facilisis facilisis leo in imperdiet. In ullamcorper consectetur lectus at porttitor. Nam at blandit felis, nec tempus odio. </p>
            </Grid.Column>
            <Grid.Column width={2}>
              <div className="vertical-divider pastel-yellow"></div>
            </Grid.Column>
            <Grid.Column width={7}>
              <Image 
                src={project2Thumbnail} 
                fluid 
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="project-snippet">
            <Grid.Column width={7}>
              <Image 
                src={project3Thumbnail} 
                fluid 
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <div className="vertical-divider pastel-green"></div>
            </Grid.Column>
            <Grid.Column width={7}>
              <h4>Project 3</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer at sem risus. Aenean blandit leo in porttitor fermentum. Praesent at justo nisi. Curabitur facilisis facilisis leo in imperdiet. In ullamcorper consectetur lectus at porttitor. Nam at blandit felis, nec tempus odio. </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  )
}

export default Projects;