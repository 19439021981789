import React from 'react';
import { Icon } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom';
import resume from '../assets/prerana-jaikumar-resume-2020.pdf';

const LINKEDIN_URL = 'https://www.linkedin.com/in/prerana-jaikumar/';

const Navbar = () => {
  return (
    <div className="main-navbar">
      <NavLink 
        to="/" 
        exact
        activeClassName="active-path"
      > 
        PROJECTS 
      </NavLink>
      <a 
        href={resume} 
        target='_blank'
      >
        RESUME
      </a>
      <NavLink 
        to="/about"
        exact
        activeClassName="active-path"
      > 
        ABOUT ME 
      </NavLink>
      <Icon 
        link
        className="social-media-link"
        name="linkedin" 
        size="large"
        onClick={() => window.open(LINKEDIN_URL, '_blank')}
      />
    </div>
  );
}

export default Navbar;