import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import Navbar from './components/Navbar';
import Projects from './pages/Projects';
import AboutMe from './pages/AboutMe';
import './App.scss';

const App = () => {
  return (
    <main>
      <Grid 
        className="main-app-container"
        columns='equal'
      >
        <Grid.Column />
        <Grid.Column width={10}>
          <Navbar />
          <Switch>
            <Route path="/" component={Projects} exact />
            <Route path="/about" component={AboutMe} exact />
          </Switch>
        </Grid.Column>
        <Grid.Column/>
      </Grid>
      <Footer />
    </main> 
  );
}

const Footer = () => {
  return (
    <Grid className="app-footer">
      <Grid.Column width={16}>
        <Grid columns='equal'>
          <Grid.Column />
          <Grid.Column width={10}>
            <p>© Copyright 2020 Prerana Jaikumar</p>
          </Grid.Column>
          <Grid.Column/>
        </Grid>
      </Grid.Column>
    </Grid>
  )
}

export default App;
